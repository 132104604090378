import React, { useState, useRef, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import './Navbar.css';
import entertainment from '../assets/entertainment.png';


export const Navbar = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const sidebarRef = useRef(null);

  // Toggle sidebar open/close
  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  // Handle click outside to close the sidebar
  const handleClickOutside = (event) => {
    if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
      setIsSidebarOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="navbar">
      <div className="navbar-logo">
      
       
      </div>
      <button className="menu-button" onClick={toggleSidebar}>
        ☰ 
      </button>
      <ul className={`nav-list ${isSidebarOpen ? 'open' : ''}`} ref={sidebarRef}>
        <li className="nav-item">
          <NavLink className="nav-link" to="/">
            <img src={entertainment} alt="Entertainment" />
          </NavLink>
        </li>
    { /*   <li className="nav-item">
          <NavLink className="nav-link" to="/telugudubbed">
          </NavLink>
        </li>
        
        <li className="nav-item">
          <NavLink className="nav-link" to="/electronics">
            <img src={electronics1} alt="Electronics" />
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink className="nav-link" to="/clothshop">
            <img src={clothing} alt="Clothing" />
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink className="nav-link" to="/shoeshowrooms">
          </NavLink>
        </li>
        */}
    
      </ul>
    </div>
  );
};

