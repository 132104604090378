import React, { useState, useEffect } from 'react';
import axios from 'axios';

const DATABASE_URL = 'https://telugumovies-fae96-default-rtdb.firebaseio.com/visitorCount.json';

export const VisitorCount = () => {
  const [visitorCount, setVisitorCount] = useState(0);

  useEffect(() => {
    const fetchVisitorCount = async () => {
      try {
        // Fetch current count
        const response = await axios.get(DATABASE_URL);
        const currentCount = response.data || 0;

        // Increment the count
        const newCount = currentCount + 1;

        // Update the count in Firebase
        await axios.put(DATABASE_URL, newCount);

        // Update local state
        setVisitorCount(newCount);
      } catch (error) {
        console.error('Error fetching or updating visitor count:', error);
      }
    };

    fetchVisitorCount();
  }, []);

  return (
    <div>
      <p>Visitor Count: {visitorCount}</p>
    </div>
  );
};
