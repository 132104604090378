



// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyCkJiDzrg7bWyzfUSLYADm5M0znrYUM-Uk",
  authDomain: "newproject-95e49.firebaseapp.com",
  databaseURL: "https://newproject-95e49-default-rtdb.firebaseio.com",
  projectId: "newproject-95e49",
  storageBucket: "newproject-95e49.appspot.com",
  messagingSenderId: "120935538466",
  appId: "1:120935538466:web:b5129f6a04f4aeb10d0f7b"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app); // Ensure 'db' is defined here
export { db }; // Correctly export 'db'

