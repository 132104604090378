import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { Navbar } from './Navigation/Navbar';
import Footer from './Footer/Footer';
import { Entertainment } from './Pages/Home/Entertainment';
import { EntertainmentPage } from './Pages/Home/EntertainmentPage';
import { NotFound } from './NotFound';
import { TeluguDubbed } from './Pages/TeluguDubbed/TeluguDubbed';
import { TeluguDubbedPage } from './Pages/TeluguDubbed/TeluguDubbedPage';
import { Requstedmovies } from './Pages/Requstedmovies/Requstedmovies';
import { RequstedMoviesPage } from './Pages/Requstedmovies/RequstedMoviesPage';
import { TeluguOld } from './Pages/TeluguOld/TeluguOld';
import { TeluguOldPage } from './Pages/TeluguOld/TeluguOldPage';
import { TeluguOldYearWise } from './Pages/TeluguOldYearWise/TeluguOldYearWise';
import { TeluguOldYearWisePage } from './Pages/TeluguOldYearWise/TeluguOldYearWisePage';
import { WebSeries } from './Pages/WebSeries/WebSeries';
import { WebSeriesPage } from './Pages/WebSeries/WebSeriesPage';

const App = () => {
    return (
        <div>
            <Navbar />
            <div className="content">
         
                <Routes>
                    <Route path="/" element={<Entertainment />} />
                    <Route path="/entertainmentStage/:id" element={<EntertainmentPage />} />   
                    <Route path="/telugudubbed" element={<TeluguDubbed />} />  
                    <Route path="/telugudubbedpage/:id" element={<TeluguDubbedPage />} />     
                    <Route path="/requstedmovies" element={<Requstedmovies />} /> 
                    <Route path="/teluguold" element={<TeluguOld />} />   
                    <Route path="/teluguoldyearwise" element={<TeluguOldYearWise />} />
                    <Route path="/teluguoldyearwisepage/:id" element={<TeluguOldYearWisePage />} />
                    <Route path="/teluguoldpage/:id" element={<TeluguOldPage />} /> 
                    <Route path="/requastedmoviespage/:id" element={<RequstedMoviesPage />} />  
                    <Route path="/webseries" element={<WebSeries />} />
                    <Route path="/webseriespage/:id" element={<WebSeriesPage />} />               
                    <Route path="*" element={<NotFound />} /> {/* 404 Page */}
                </Routes>
              
            </div>
            <Footer />
        </div>
    );
};

export default App;
