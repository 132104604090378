import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { Button, Col, Row, Form, Spinner, Alert } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';
import { db } from '../../Firebase';
import { collection, getDocs, addDoc, orderBy, query, serverTimestamp } from 'firebase/firestore';
import './Entertainment.css';


export const Entertainment = () => {
    const navigate = useNavigate();
    const [tasks, setTasks] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [message, setMessage] = useState('');
    const [newMovie, setNewMovie] = useState({ name: '', description: '' });
    const [submittedRequests, setSubmittedRequests] = useState([]);
    const [loading, setLoading] = useState(true);
    const [language] = useState('telugu');
    const [isMobile, setIsMobile] = useState(window.matchMedia("(max-width: 768px)").matches);

    // Pagination states
    const [currentPage, setCurrentPage] = useState(1);
    const moviesPerPage = 20;

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                // Fetch tasks and order by createdAt timestamp in descending order
                const tasksCollection = collection(db, language);
                const tasksQuery = query(tasksCollection, orderBy('createdAt', 'desc'));
                const tasksSnapshot = await getDocs(tasksQuery);
                const fetchedTasks = tasksSnapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data(),
                }));
                setTasks(fetchedTasks);

                // Fetch submitted requests without ordering
                const requestsCollection = collection(db, 'requests');
                const requestsSnapshot = await getDocs(requestsCollection);
                const fetchedRequests = requestsSnapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data(),
                }));
                setSubmittedRequests(fetchedRequests);
            } catch (error) {
                setMessage('Failed to fetch data. Please try again later.');
            } finally {
                setLoading(false);
            }
        };

        fetchData();

        const handleResize = () => {
            setIsMobile(window.matchMedia("(max-width: 768px)").matches);
        };

        window.matchMedia("(max-width: 768px)").addEventListener('change', handleResize);
        return () => {
            window.matchMedia("(max-width: 768px)").removeEventListener('change', handleResize);
        };
    }, [language]);

    const handleSearchChange = useCallback(
        _.debounce((value) => setSearchQuery(value), 300),
        []
    );

    const filteredMovies = useMemo(() => {
        return tasks.filter((task) =>
            task.name.toLowerCase().includes(searchQuery.toLowerCase())
        );
    }, [tasks, searchQuery]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNewMovie((prev) => ({ ...prev, [name]: value }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const requestsCollection = collection(db, 'requests');
            const newMovieData = {
                ...newMovie,
                createdAt: serverTimestamp(), // Add createdAt timestamp
            };
            const docRef = await addDoc(requestsCollection, newMovieData);
            setMessage('Movie request submitted successfully!');
            const newRequest = { id: docRef.id, ...newMovieData };
            setSubmittedRequests([newRequest, ...submittedRequests]);
            setNewMovie({ name: '', description: '' });
        } catch (error) {
            setMessage('Failed to submit the movie request. Please try again later.');
        }
    };

    const handleDetailsClick = (id) => {
        navigate(`/entertainmentStage/${id}`);
    };

    // Handle pagination
    const indexOfLastMovie = currentPage * moviesPerPage;
    const indexOfFirstMovie = indexOfLastMovie - moviesPerPage;
    const currentMovies = filteredMovies.slice(indexOfFirstMovie, indexOfLastMovie);

    const totalPages = Math.ceil(filteredMovies.length / moviesPerPage);

    const paginate = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const headerStyle = {
        color: '#000',
        textAlign: 'center',
        padding: '2px 0',
        fontFamily: 'Arial, sans-serif',
        marginTop: '-40px'
    };
    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };
    
    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };
    const AdSenseAd = () => {
        useEffect(() => {
            if (window.adsbygoogle) {
                try {
                    (window.adsbygoogle = window.adsbygoogle || []).push({});
                } catch (e) {
                    console.error("AdSense error", e);
                }
            }
        }, []);
    

        return (
            <div style={{ textAlign: 'center', margin: '20px 0' }}>
             <ins
    className="adsbygoogle"
    style={{ display: 'block', width: '100%', height: '90px' }}
    data-ad-client="ca-pub-1196895396100273"
    data-ad-slot="1234567890" // Replace with actual ad slot ID
    data-ad-format="auto"
    data-full-width-responsive="true"
></ins>

            </div>
        );
    };

    return (
        <div className="container mt-4">
    
            <header className="container-fluid text-center py-4" style={headerStyle}>
                <h1 className="display-4 font-weight-bold text-light fontWeight-bold" style={{ fontWeight: '900' }}>IMAX1 THEATER</h1>
                <p
    className="lead"
    style={{
        fontFamily: 'Georgia, serif', // Example font family
        fontSize: '1rem',           // Adjust the font size as needed
        fontWeight: '400',            // Optional: Set font weight
        color: '#eee',                // Optional: Set font color
        textShadow: '1px 1px 4px #000' // Optional: Add shadow for emphasis
    }}
>
    Your Destination for Telugu Movies
</p>
            </header>
            <br></br>
            <div className="filter-buttons mb-3" style={{ padding: '0px 0' }}>
    <Row className="gy-3 d-flex flex-nowrap">
        <Col className="d-flex justify-content-center" style={{ flex: 1 }}>
            <Button variant="primary" onClick={() => navigate('/')} style={{  width: '100%', paddingLeft: '10px', borderRadius: '50px',paddingRight:'10px' }}>
                Latest
            </Button>
        </Col>
        <Col className="d-flex justify-content-center" style={{ flex: 1 }}>
            <Button variant="primary" onClick={() => navigate('/telugudubbed')} style={{ width: '100%', paddingLeft: '10px', borderRadius: '50px',paddingRight:'10px'}}>
                Telugu Dubbed
            </Button>
        </Col>
        <Col className="d-flex justify-content-center" style={{ flex: 1 }}>
            <Button variant="primary" onClick={() => navigate('/teluguold')} style={{  width: '100%', paddingLeft: '10px', borderRadius: '50px',paddingRight:'10px' }}>
                Telugu Old
            </Button>
        </Col>
        <Col className="d-flex justify-content-center" style={{ flex: 1 }}>
            <Button variant="primary" onClick={() => navigate('/teluguoldyearwise')} style={{  width: '100%', paddingLeft: '10px', borderRadius: '50px',paddingRight:'10px' }}>
                Telugu Old Year Wise
            </Button>
        </Col>
        <Col className="d-flex justify-content-center" style={{ flex: 1 }}>
            <Button variant="primary" onClick={() => navigate('/webseries')} style={{  width: '100%', paddingLeft: '10px', borderRadius: '50px',paddingRight:'10px' }}>
                WebSeries
            </Button>
        </Col>
        <Col className="d-flex justify-content-center" style={{ flex: 1 }}>
            <Button variant="primary" onClick={() => navigate('/requstedmovies')} style={{  width: '100%', paddingLeft: '10px', borderRadius: '50px',paddingRight:'10px'}}>
                Requested Movies
            </Button>
        </Col>
    </Row>
</div>


         
            <div className="mb-3">
                <Form.Control
                    type="text"
                    placeholder="Search for movies..."
                    value={searchQuery}
                    onChange={(e) => handleSearchChange(e.target.value)}
                />
            </div>

            {/* Movies List */}
            <Row>
                {loading ? (
                    <Col className="text-center">
                        <Spinner animation="border" role="status" />
                        <p>Loading movies...</p>
                    </Col>
                ) : (
                    currentMovies.length === 0 ? (
                        <Col className="text-center">
                            <Alert variant="info">No movies found for your search.</Alert>
                        </Col>
                    ) : (
                        currentMovies.map((task) => (
                            <Col key={task.id} xs={4} sm={4} md={3} lg={2} className="mb-4">
                                <div className="text-center">
                                    <div
                                        className="imageView"
                                        onClick={() => handleDetailsClick(task.id)}
                                        style={{
                                            height: isMobile ? '150px' : '250px',
                                            width: '100%',
                                            boxShadow: '5px 5px 15px rgba(0, 0, 0, 0.3)',
                                            borderRadius: '10px',
                                            overflow: 'hidden',
                                            cursor: 'pointer',
                                            margin: '0 auto',
                                        }}
                                    >
                                        <img
                                            className="image-container"
                                            src={task.url || 'path/to/placeholder.jpg'}
                                            alt={task.name}
                                            style={{ height: '100%', width: '100%', objectFit: 'cover', borderRadius: '10px' }}
                                            loading="lazy"
                                        />
                                    </div>
                                    <p className="mb-1 text-truncate" style={{ fontSize: 'medium', color: 'blue', fontWeight: 'bold' }}>
                                        {task.name}
                                    </p>
                                    <Button
                                        onClick={() => handleDetailsClick(task.id)}
                                        className="btn btn-sm"
                                        style={{ width: '100%', backgroundColor: 'gold', color: 'green', border: 'none', fontWeight: 'bold' }}
                                    >
                                        IMAX1
                                    </Button>
                                </div>
                            </Col>
                        ))
                    )
                )}
            </Row>
     <div
    className="pagination mt-4 text-center"
    style={{ overflowX: 'auto', whiteSpace: 'nowrap', padding: '10px' }}
    >
    <Button
        onClick={handlePreviousPage}
        disabled={currentPage === 1}
        variant="outline-primary"
        style={{ margin: '0 5px' }}
    >
        Previous
    </Button>
    {[...Array(totalPages)].map((_, index) => (
        <Button
            key={index}
            onClick={() => setCurrentPage(index + 1)}
            variant={currentPage === index + 1 ? "primary" : "outline-primary"}
            style={{ margin: '0 5px' }}
        >
            {index + 1}
        </Button>
    ))}
    <Button
        onClick={handleNextPage}
        disabled={currentPage === totalPages}
        variant="outline-primary"
        style={{ margin: '0 5px' }}
    >
        Next
    </Button>
</div>


            {/* Add Movie Request Form */}
            <div className="movie-request-form mt-5">
                <h3>Request a Movie</h3>
                {message && <Alert variant="info">{message}</Alert>}
                <Form onSubmit={handleSubmit}>
                    <Form.Group controlId="movieName" className="mb-3">
                        <Form.Label>Movie Name</Form.Label>
                        <Form.Control
                            type="text"
                            name="name"
                            value={newMovie.name}
                            onChange={handleInputChange}
                            required
                        />
                    </Form.Group>

                    <Form.Group controlId="movieDescription" className="mb-3">
                        <Form.Label>Movie Description</Form.Label>
                        <Form.Control
                            type="text"
                            name="description"
                            value={newMovie.description}
                            onChange={handleInputChange}
                            required
                        />
                    </Form.Group>

                    <Button variant="primary" type="submit">
                        Submit
                    </Button>
                </Form>
                <br></br>
            </div>
        </div>
    );
};
