// src/components/NotFound.js
import React from 'react';
import { Container } from 'react-bootstrap';

export const NotFound = () => {
    return (
        <Container className="text-center mt-5">
            <h1>404 - Page Not Found</h1>
            <p>Sorry, the page you are looking for does not exist.</p>
        </Container>
    );
};

