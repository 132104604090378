import React, { useState, useEffect, useCallback } from 'react';
import { Button, Col, Row, Form } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useNavigate } from 'react-router-dom';
import { db } from '../../Firebase';
import { collection, getDocs } from 'firebase/firestore';

export const TeluguOld = () => {
    const navigate = useNavigate();
    const [tasks, setTasks] = useState([]);
    const [message, setMessage] = useState('');
    const [searchQuery, setSearchQuery] = useState('');
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    // Pagination state
    const [currentPage, setCurrentPage] = useState(1);
    const moviesPerPage = 20;

    useEffect(() => {
        const fetchData = async () => {
            try {
                const tasksCollection = collection(db, 'teluguold');
                const snapshot = await getDocs(tasksCollection);
                const fetchedTasks = snapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data(),
                }));
                setTasks(fetchedTasks.reverse());
            } catch (error) {
                setMessage('Failed to fetch data. Please try again later.');
            }
        };
        fetchData();
    }, []);

    const handleReadMoreClick = useCallback(
        (id) => {
            navigate(`/teluguoldpage/${id}`);
        },
        [navigate]
    );

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    const filteredTasks = tasks.filter((task) =>
        task.name.toLowerCase().includes(searchQuery.toLowerCase())
    );

    // Pagination logic
    const indexOfLastMovie = currentPage * moviesPerPage;
    const indexOfFirstMovie = indexOfLastMovie - moviesPerPage;
    const currentMovies = filteredTasks.slice(indexOfFirstMovie, indexOfLastMovie);
    const totalPages = Math.ceil(filteredTasks.length / moviesPerPage);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    const headerStyle = {
        color: '#000',
        textAlign: 'center',
        padding: '2px 0',
        fontFamily: 'Arial, sans-serif',
        marginTop: '-40px'
    };

    return (
        <div className="container mt-4">
            <header className="container-fluid text-center py-4" style={headerStyle}>
                <h1 className="display-4 font-weight-bold text-light" style={{ fontWeight: '900' }}>IMAX1 THEATER</h1>
                <p className="lead" style={{ fontFamily: 'Georgia, serif', fontSize: '1rem', fontWeight: '400', color: '#eee', textShadow: '1px 1px 4px #000' }}>
                    Your Destination for Telugu Movies
                </p>
            </header>
            <br />

            <div className="filter-buttons mb-3" style={{ padding: '0px 0' }}>
    <Row className="gy-3 d-flex flex-nowrap">
        <Col className="d-flex justify-content-center" style={{ flex: 1 }}>
            <Button variant="primary" onClick={() => navigate('/')} style={{  width: '100%', paddingLeft: '10px', borderRadius: '50px',paddingRight:'10px' }}>
                Latest
            </Button>
        </Col>
        <Col className="d-flex justify-content-center" style={{ flex: 1 }}>
            <Button variant="primary" onClick={() => navigate('/telugudubbed')} style={{ width: '100%', paddingLeft: '10px', borderRadius: '50px',paddingRight:'10px'}}>
                Telugu Dubbed
            </Button>
        </Col>
        <Col className="d-flex justify-content-center" style={{ flex: 1 }}>
            <Button variant="primary" onClick={() => navigate('/teluguold')} style={{  width: '100%', paddingLeft: '10px', borderRadius: '50px',paddingRight:'10px' }}>
                Telugu Old
            </Button>
        </Col>
        <Col className="d-flex justify-content-center" style={{ flex: 1 }}>
            <Button variant="primary" onClick={() => navigate('/teluguoldyearwise')} style={{  width: '100%', paddingLeft: '10px', borderRadius: '50px',paddingRight:'10px' }}>
                Telugu Old Year Wise
            </Button>
        </Col>
        <Col className="d-flex justify-content-center" style={{ flex: 1 }}>
            <Button variant="primary" onClick={() => navigate('/webseries')} style={{  width: '100%', paddingLeft: '10px', borderRadius: '50px',paddingRight:'10px' }}>
                WebSeries
            </Button>
        </Col>
        <Col className="d-flex justify-content-center" style={{ flex: 1 }}>
            <Button variant="primary" onClick={() => navigate('/requstedmovies')} style={{  width: '100%', paddingLeft: '10px', borderRadius: '50px',paddingRight:'10px'}}>
                Requested Movies
            </Button>
        </Col>
    </Row>
</div>


            {/* Search Input */}
            <Form.Group className="mb-4">
                <Form.Control
                    type="text"
                    placeholder="Search for a movie..."
                    value={searchQuery}
                    onChange={handleSearchChange}
                />
            </Form.Group>

            {/* Movies List */}
            <Row>
                {currentMovies.length === 0 ? (
                    <p>{message || 'No movies found.'}</p>
                ) : (
                    currentMovies.map((task) => (
                        <Col key={task.id} xs={4} sm={4} md={3} lg={2} className="mb-4">
                            <div className="text-center">
                                <div
                                    className="imageView"
                                    style={{
                                        height: isMobile ? '150px' : '250px',
                                        width: '100%',
                                        boxShadow: '5px 5px 15px rgba(0, 0, 0, 0.3)',
                                        borderRadius: '10px',
                                        overflow: 'hidden',
                                        cursor: 'pointer',
                                        margin: '0 auto',
                                    }}
                                    onClick={() => handleReadMoreClick(task.id)}
                                >
                                    <img
                                        src={task.url}
                                        alt={task.name}
                                        className="img-fluid"
                                        style={{ height: '100%', width: '100%', objectFit: 'cover' }}
                                        loading="lazy"
                                    />
                                </div>
                                <p className="mt-2 mb-1 text-truncate" style={{ fontSize: 'medium', color: 'blue', fontWeight: 'bold' }}>
                                    {task.name}
                                </p>
                                <Button
                                    onClick={() => handleReadMoreClick(task.id)}
                                    className="btn btn-sm"
                                    style={{ width: '100%', backgroundColor: 'gold', color: 'black', border: 'none', fontWeight: 'bold' }}
                                >
                                    IMAX1
                                </Button>
                            </div>
                        </Col>
                    ))
                )}
            </Row>

            {/* Pagination */}
            <div className="pagination mt-4 text-center" style={{ overflowX: 'auto', whiteSpace: 'nowrap', padding: '10px' }}>
                <Button onClick={() => paginate(currentPage - 1)} disabled={currentPage === 1} variant="outline-primary" style={{ margin: '0 5px' }}>
                    Previous
                </Button>
                {[...Array(totalPages)].map((_, index) => (
                    <Button
                        key={index}
                        onClick={() => paginate(index + 1)}
                        variant={currentPage === index + 1 ? "primary" : "outline-primary"}
                        style={{ margin: '0 5px' }}
                    >
                        {index + 1}
                    </Button>
                ))}
                <Button onClick={() => paginate(currentPage + 1)} disabled={currentPage === totalPages} variant="outline-primary" style={{ margin: '0 5px' }}>
                    Next
                </Button>
            </div>
            <br />
        </div>
    );
};
