import React, { useState, useEffect, useMemo } from 'react';
import { Button, Col, Row, Form, Spinner, Alert } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { collection, getDocs } from 'firebase/firestore'; 
import { db } from '../../Firebase';
import _ from 'lodash';

export const WebSeries = () => {
    const navigate = useNavigate();
    const [tasks, setTasks] = useState([]);  // All movies
    const [displayedTasks, setDisplayedTasks] = useState([]); // Paginated tasks
    const [searchQuery, setSearchQuery] = useState('');
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(true);
    const [language] = useState('webseries');
    const [isMobile, setIsMobile] = useState(window.matchMedia("(max-width: 768px)").matches);

    // Pagination states
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize] = useState(10); // Set the page size to 10 items per page
    const [totalPages, setTotalPages] = useState(1);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const tasksCollection = collection(db, language); // Reference to the collection
                const tasksSnapshot = await getDocs(tasksCollection);
                const fetchedTasks = tasksSnapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                }));
                setTasks(fetchedTasks.reverse());
                setTotalPages(Math.ceil(fetchedTasks.length / pageSize)); // Set total pages based on new pageSize
            } catch (error) {
                setMessage('Failed to fetch data. Please try again later.');
            } finally {
                setLoading(false);
            }
        };

        fetchData();

        const handleResize = () => {
            setIsMobile(window.matchMedia("(max-width: 768px)").matches);
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [language]);

    useEffect(() => {
        // Paginate tasks when currentPage or tasks change
        const startIndex = (currentPage - 1) * pageSize;
        const endIndex = startIndex + pageSize;
        setDisplayedTasks(tasks.slice(startIndex, endIndex)); // Slice tasks for the current page
    }, [currentPage, tasks, pageSize]);

    const handleSearchChange = useMemo(
        () => _.debounce((value) => {
            setSearchQuery(value);
        }, 300),
        []
    );

    const filteredMovies = useMemo(() => {
        return displayedTasks.filter((task) =>
            task.name.toLowerCase().includes(searchQuery.toLowerCase())
        );
    }, [displayedTasks, searchQuery]);

    const handleDetailsClick = (id) => {
        navigate(`/webseriespage/${id}`);
    };

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const headerStyle = {
        color: '#000',
        textAlign: 'center',
        padding: '2px 0',
        fontFamily: 'Arial, sans-serif',
        marginTop: '-40px'
    };

    return (
        <div className="container mt-4">
            <header className="container-fluid text-center py-4" style={headerStyle}>
                <h1 className="display-4 font-weight-bold text-light" style={{ fontWeight: '900' }}>IMAX1 THEATER</h1>
                <p
    className="lead"
    style={{
        fontFamily: 'Georgia, serif', // Example font family
        fontSize: '1rem',           // Adjust the font size as needed
        fontWeight: '400',            // Optional: Set font weight
        color: '#eee',                // Optional: Set font color
        textShadow: '1px 1px 4px #000' // Optional: Add shadow for emphasis
    }}
>
    Your Destination for Telugu Movies
</p>
            </header>
            <br></br>
            
            <div className="filter-buttons mb-3" style={{ padding: '0px 0' }}>
    <Row className="gy-3 d-flex flex-nowrap">
        <Col className="d-flex justify-content-center" style={{ flex: 1 }}>
            <Button variant="primary" onClick={() => navigate('/')} style={{ width: '100%', paddingLeft: '10px', borderRadius: '50px',paddingRight:'10px' }}>
                Latest
            </Button>
        </Col>
        <Col className="d-flex justify-content-center" style={{ flex: 1 }}>
            <Button variant="primary" onClick={() => navigate('/telugudubbed')} style={{  width: '100%', paddingLeft: '10px', borderRadius: '50px',paddingRight:'10px'}}>
                Telugu Dubbed
            </Button>
        </Col>
        <Col className="d-flex justify-content-center" style={{ flex: 1 }}>
            <Button variant="primary" onClick={() => navigate('/teluguold')} style={{  width: '100%', paddingLeft: '10px', borderRadius: '50px',paddingRight:'10px'}}>
                Telugu Old
            </Button>
        </Col>
        <Col className="d-flex justify-content-center" style={{ flex: 1 }}>
            <Button variant="primary" onClick={() => navigate('/teluguoldyearwise')} style={{  width: '100%', paddingLeft: '10px', borderRadius: '50px',paddingRight:'10px' }}>
                Telugu Old Year Wise
            </Button>
        </Col>
        <Col className="d-flex justify-content-center" style={{ flex: 1 }}>
            <Button variant="primary" onClick={() => navigate('/webseries')} style={{  width: '100%', paddingLeft: '10px', borderRadius: '50px',paddingRight:'10px'}}>
                WebSeries
            </Button>
        </Col>
        <Col className="d-flex justify-content-center" style={{ flex: 1 }}>
            <Button variant="primary" onClick={() => navigate('/requstedmovies')} style={{  width: '100%', paddingLeft: '10px', borderRadius: '50px',paddingRight:'10px'}}>
                Requested Movies
            </Button>
        </Col>
    </Row>
</div>

            {/* Search Movies */}
            <div className="mb-3">
                <Form.Control
                    type="text"
                    placeholder="Search for movies..."
                    value={searchQuery}
                    onChange={(e) => handleSearchChange(e.target.value)}
                />
            </div>

            {/* Movies List */}
            <Row>
                {loading ? (
                    <Col className="text-center">
                        <Spinner animation="border" role="status" />
                        <p>Loading movies...</p>
                    </Col>
                ) : (
                    filteredMovies.length === 0 ? (
                        <Col className="text-center">
                            <Alert variant="info">No movies found for your search.</Alert>
                        </Col>
                    ) : (
                        filteredMovies.map((task) => (
                            <Col key={task.id} xs={4} sm={4} md={3} lg={2} className="mb-4">
                                <div className="text-center">
                                    <div
                                        id={`image-${task.id}`}
                                        className="imageView"
                                        onClick={() => handleDetailsClick(task.id)}
                                        style={{
                                            height: isMobile ? '150px' : '250px',
                                            width: '100%',
                                            boxShadow: '5px 5px 15px rgba(0, 0, 0, 0.3)',
                                            borderRadius: '10px',
                                            overflow: 'hidden',
                                            cursor: 'pointer',
                                            margin: '0 auto',
                                        }}
                                    >
                                        <img
                                            className="image-container"
                                            src={task.url}
                                            alt={task.name}
                                            style={{ height: '100%', width: '100%', objectFit: 'cover', borderRadius: '10px' }}
                                            loading="lazy"
                                        />
                                    </div>
                                    <p className="mb-1 text-truncate" style={{ fontSize: 'medium', color: 'blue', fontWeight: 'bold' }}>
                                        {task.name}
                                    </p>
                                    <Button
                                        onClick={() => handleDetailsClick(task.id)}
                                        className="btn btn-sm"
                                        style={{ width: '100%', backgroundColor: 'gold', color: 'black', border: 'none',fontWeight: 'bold' }}
                                    >
                                        IMAX1
                                    </Button>
                                </div>
                            </Col>
                        ))
                    )
                )}
            </Row>
            <div
    className="pagination mt-4 text-center"
    style={{ overflowX: 'auto', whiteSpace: 'nowrap', padding: '10px' }}
    >
    <Button
        onClick={handlePreviousPage}
        disabled={currentPage === 1}
        variant="outline-primary"
        style={{ margin: '0 5px' }}
    >
        Previous
    </Button>
    {[...Array(totalPages)].map((_, index) => (
        <Button
            key={index}
            onClick={() => setCurrentPage(index + 1)}
            variant={currentPage === index + 1 ? "primary" : "outline-primary"}
            style={{ margin: '0 5px' }}
        >
            {index + 1}
        </Button>
    ))}
    <Button
        onClick={handleNextPage}
        disabled={currentPage === totalPages}
        variant="outline-primary"
        style={{ margin: '0 5px' }}
    >
        Next
    </Button>
</div>
            <br></br>
          
        </div>
        
    );
};
